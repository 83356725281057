import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import { CommunitySelector, CallToAction, SEO } from "components"
import { usePreviewData } from "utils"

import Hero from "./_hero"

import Updates from "./_updates"

function HomePage() {
  const data = useStaticQuery(
    graphql`
      {
        prismicHome {
          data {
            seo_title {
              text
            }
            seo_description {
              text
            }
            seo_image {
              url
              alt
            }
            hero_image {
              url
              alt

              fluid(maxWidth: 1920) {
                ...GatsbyPrismicImageFluid
              }
            }
            hero_video {
              url
            }
            hero_title {
              text
            }
            hero_subtitle {
              text
            }
            community_subtext {
              html
            }
            promo_image {
              url
              alt

              fluid(maxWidth: 1200) {
                ...GatsbyPrismicImageFluid
              }
            }
            promo_video {
              url
            }
            promo_title {
              text
            }
            promo_description {
              html
            }
            promo_button_text
            promo_button_link {
              link_type
              url
            }
            promo_style
            promo_pattern
            promo_color
            promo_side
            block_1_image {
              url
              alt

              fluid(maxWidth: 1200) {
                ...GatsbyPrismicImageFluid
              }
            }
            block_1_video {
              url
            }
            block_1_title {
              text
            }
            block_1_description {
              html
            }
            block_1_button_text
            block_1_button_link {
              link_type
              url
            }
            block_1_style
            block_1_pattern
            block_1_color
            block_1_side
            block_2_image {
              url
              alt

              fluid(maxWidth: 1200) {
                ...GatsbyPrismicImageFluid
              }
            }
            block_2_video {
              url
            }
            block_2_title {
              text
            }
            block_2_description {
              html
            }
            block_2_button_text
            block_2_button_link {
              link_type
              url
            }
            block_2_style
            block_2_pattern
            block_2_color
            block_2_side
          }
        }
        updates: allPrismicPost(
          sort: { order: DESC, fields: data___date }
          filter: { data: { featured: { eq: "Yes" } } }
        ) {
          nodes {
            uid
            data {
              title {
                text
              }
              tag
              date
              excerpt
              body {
                ... on PrismicPostBodyCallToAction {
                  id
                }
                ... on PrismicPostBodyImage {
                  id
                }
                ... on PrismicPostBodyText {
                  id
                }
              }
              image {
                url
                alt

                fluid(maxWidth: 600) {
                  ...GatsbyPrismicImageFluid
                }
              }
              featured
            }
          }
        }
      }
    `
  )
  // Merge preview data with static query if possible
  const MERGED_DATA = usePreviewData(data)
  const { prismicHome, updates } = MERGED_DATA

  return (
    <>
      <SEO
        title={prismicHome.data.seo_title}
        description={prismicHome.data.seo_description}
        image={prismicHome.data.seo_image}
      />
      <Hero
        image={prismicHome.data.hero_image}
        video={prismicHome.data.hero_video}
        heading={prismicHome.data.hero_title.text}
        subheading={prismicHome.data.hero_subtitle.text}
      />
      {prismicHome.data.promo_title && prismicHome.data.promo_title.text && (
        <CallToAction
          image={prismicHome.data.promo_image}
          video={prismicHome.data.promo_video}
          heading={prismicHome.data.promo_title}
          text={prismicHome.data.promo_description}
          button={prismicHome.data.promo_button_text}
          link={prismicHome.data.promo_button_link}
          style={prismicHome.data.promo_style}
          pattern={prismicHome.data.promo_pattern}
          bg={prismicHome.data.promo_color}
          align={prismicHome.data.promo_side}
        />
      )}
      <CommunitySelector subtext={prismicHome.data.community_subtext} />
      {prismicHome.data.block_1_title &&
        prismicHome.data.block_1_title.text && (
          <CallToAction
            image={prismicHome.data.block_1_image}
            video={prismicHome.data.block_1_video}
            heading={prismicHome.data.block_1_title}
            text={prismicHome.data.block_1_description}
            button={prismicHome.data.block_1_button_text}
            link={prismicHome.data.block_1_button_link}
            style={prismicHome.data.block_1_style}
            pattern={prismicHome.data.block_1_pattern}
            bg={prismicHome.data.block_1_color}
            align={prismicHome.data.block_1_side}
          />
        )}
      {prismicHome.data.block_2_title &&
        prismicHome.data.block_2_title.text && (
          <CallToAction
            image={prismicHome.data.block_2_image}
            video={prismicHome.data.block_2_video}
            heading={prismicHome.data.block_2_title}
            text={prismicHome.data.block_2_description}
            button={prismicHome.data.block_2_button_text}
            link={prismicHome.data.block_2_button_link}
            style={prismicHome.data.block_2_style}
            pattern={prismicHome.data.block_2_pattern}
            bg={prismicHome.data.block_2_color}
            align={prismicHome.data.block_2_side}
          />
        )}
      {updates && updates.nodes && updates.nodes.length > 0 && (
        <Updates
          title={"Latest News"}
          updates={updates.nodes.slice(0, 3)}
          showSeeAll={true}
        />
      )}
    </>
  )
}

export default HomePage
