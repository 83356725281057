import React from "react"
import { Link } from "gatsby"
import dayjs from "dayjs"

import { Box, Carousel, H1, UpdateCard, Wrapper, Button } from "components"

const Updates = ({ title, updates }) => (
  <>
    {title && (
      <Wrapper as="section" id="updates" pt={[5, 6]}>
        <H1 children={title} mb={[4, 5]} />
      </Wrapper>
    )}
    <Carousel
      as="section"
      id="updates-carousel"
      settings={{
        infinite: false,
        initialSlide: 0,
        slidesToShow: 3,
        slidesToScroll: 3,
        variableWidth: false,
        responsive: [
          {
            breakpoint: 1280,
            settings: {
              initialSlide: 0,
              slidesToShow: 2,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 640,
            settings: {
              initialSlide: 0,
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      }}
      responsive={false}
      children={[
        ...updates.map((update, index) => {
          if (update.data.body && update.data.body[0]) {
            return (
              <Box pr={[4, 5]} key={"updates" + update.uid + index}>
                <UpdateCard
                  useLink={update.data.body && update.data.body[0]}
                  tag={update.data.tag}
                  to={"/updates/" + update.uid + "/"}
                  image={update.data.image}
                  heading={update.data.title && update.data.title.text}
                  excerpt={update.data.excerpt}
                  date={dayjs(update.data.date).format("MMMM D, YYYY")}
                />
              </Box>
            )
          } else {
            return null
          }
        }),
      ]}
    />
    <Wrapper pt={4}>
      <Button
        children={`See all updates`}
        variant="alt"
        as={Link}
        to="/news/"
      />
    </Wrapper>
  </>
)

export default Updates
