import React, { useEffect } from "react"
import { Link as ScrollLink } from "react-scroll"
import css from "@styled-system/css"

import { useHeader } from "contexts/header"

import { AspectRatio, Box, H1, H3, Image, Wrapper } from "components"

function Hero({ image, video, heading, subheading }) {
  const { setHeaderMode } = useHeader()

  useEffect(() => {
    if ((image && image.url) || (video && video.url)) {
      setHeaderMode("transparent")
    } else {
      setHeaderMode("solid")
    }
  }, [])

  return (
    <Box
      as="section"
      id="hero"
      position="relative"
      height={["initial", "calc(100vh)"]}
      css={css({
        overflow: "hidden",
      })}
    >
      {video && video.url && (
        <>
          <video
            preload="metadata"
            autoPlay
            muted
            playsInline
            css={css({
              position: "absolute !important",
              top: 0,
              left: 0,
              display: ["none", "block"],
              width: "100%",
              height: "100%",
              objectFit: "cover",
              userSelect: "none",
              pointerEvents: "none",
              "@supports (-ms-ime-align:auto)": {
                top: "50%",
                left: " 50%",
                transform: "translateX(-50%) translateY(-50%)",
                minWidth: "100%",
                minHeight: "100%",
                width: "auto",
                height: "auto",
                overflow: "hidden",
              },
              "@media screen and (-ms-high-contrast: none)": {
                top: "50%",
                left: "50%",
                transform: "translateX(-50%) translateY(-50%)",
                minWidth: "100%",
                minHeight: "100%",
                width: "auto",
                height: "auto",
                overflow: "hidden",
              },
            })}
          >
            <source src={video.url + "#t0.5"} type="video/mp4" />
          </video>
          <Box display={["block", "none"]} css={css({ overflow: "hidden" })}>
            <AspectRatio ratio="6:7">
              <video
                preload="metadata"
                autoPlay
                muted
                playsInline
                css={css({
                  position: "absolute !important",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  userSelect: "none",
                  pointerEvents: "none",
                  "@supports (-ms-ime-align:auto)": {
                    top: "50%",
                    left: " 50%",
                    transform: "translateX(-50%) translateY(-50%)",
                    minWidth: "100%",
                    minHeight: "100%",
                    width: "auto",
                    height: "auto",
                    overflow: "hidden",
                  },
                  "@media screen and (-ms-high-contrast: none)": {
                    top: "50%",
                    left: "50%",
                    transform: "translateX(-50%) translateY(-50%)",
                    minWidth: "100%",
                    minHeight: "100%",
                    width: "auto",
                    height: "auto",
                    overflow: "hidden",
                  },
                })}
              >
                <source src={video.url + "#t0.5"} type="video/mp4" />
              </video>
            </AspectRatio>
          </Box>
        </>
      )}
      {(!video || !video.url) && (
        <>
          <Image
            src={image}
            alt={image.alt}
            loading="eager"
            css={css({
              position: "absolute !important",
              top: 0,
              left: 0,
              display: ["none", "block"],
              width: "100%",
              height: "100%",
            })}
          />
          <Box display={["block", "none"]}>
            <AspectRatio ratio="6:7">
              <Image
                src={image}
                alt={image.alt}
                loading="eager"
                css={css({
                  position: "absolute !important",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                })}
              />
            </AspectRatio>
          </Box>
        </>
      )}
      <Wrapper
        css={css({
          position: "relative",
          height: "100%",
          "> *": {
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            height: "100%",
          },
        })}
      >
        <Box
          maxWidth={["none", 480]}
          mx={[-3, 0]}
          mb={[0, 4, 5]}
          px={[3, 4]}
          py={[4, 4]}
          borderBottom="1px solid"
          borderColor={["border", "transparent"]}
          bg="background"
        >
          <H1 as="h1">{heading}</H1>
          {subheading && (
            <H3 mt={2} lineHeight={1.25}>
              {subheading}
            </H3>
          )}
          <Box mt={24}>
            <ScrollLink
              aria-label={`Scroll down`}
              role="button"
              to="communities"
              smooth={true}
              offset={-48}
              css={css({
                display: "inline-block",
                m: -2,
                p: 2,
                textDecoration: "none",
                cursor: "pointer",
              })}
            >
              <svg
                viewBox="0 0 32 32"
                fill="none"
                css={css({
                  width: 4,
                  height: 4,
                  verticalAlign: "top",
                  color: "text",
                })}
              >
                <rect
                  x={0.5}
                  y={0.5}
                  width={31}
                  height={31}
                  rx={15.5}
                  stroke="currentColor"
                />
                <path
                  d="M15.91 22.576V9M21 17.91L15.91 23l-5.092-5.09"
                  stroke="currentColor"
                />
              </svg>
            </ScrollLink>
          </Box>
        </Box>
      </Wrapper>
    </Box>
  )
}

export default Hero
